// src/components/NotFound.jsx
import React from 'react';

const NotFound = () => (
  <div className='body-content'>
    <h1>404</h1>
    <br/>
    <article>
      <p>Not Found...</p>
    </article>
  </div>
);

export default NotFound;