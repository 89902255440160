import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Profile = () => {
    const apiBase = 'http://localhost:5153/';
    const [profileInfo, setProfileInfo] = useState(null);
    
    useEffect(() => {
        const strProfileInfo = localStorage.getItem("profile");
        const oProfileInfo = JSON.parse(strProfileInfo);

        oProfileInfo.token = localStorage.getItem("token");

        setProfileInfo(oProfileInfo);
    }, []);

    const getSomethingFromAdmin = async () => {
        axios.get(`${apiBase}admin/Profile?email=${profileInfo.email}`, {
            headers:{'Authorization': `Bearer ${profileInfo.token}`}
        }).then(function(response){
            console.log(response);
        }).catch(function(error){
            console.log(error);
        });
    }

    return (
        <div className='body-content'>
            <h1>Perfil</h1>
            <br/>
            <article>
                <img src={profileInfo && profileInfo.picture} alt="profile info" />
                <p>Hola {profileInfo && profileInfo.name}</p>
            </article>
            <article>
                <button onClick={getSomethingFromAdmin}>click here to test admin</button>
            </article>
        </div>
    );
};

export default Profile;