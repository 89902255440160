import { createSlice } from '@reduxjs/toolkit';
import {jwtDecode as jwt_decode} from 'jwt-decode';

const sessionSlice = createSlice({
  name: 'session',
  initialState: null,
  reducers: {
    getSessionInformation: (state) => { return state = decodeSession(); },
    logInSession: (state) => { return state = logIn(); },
    logOutSession: (state) => { return state = logOut(); }
  }
});

const decodeSession = () => {
  try {
    let token = localStorage.getItem("token");
    let info = localStorage.getItem("profile");
    
    if(token && token !== null && token !== "null") {
      const decoded = jwt_decode(token);
      const objInfo = JSON.parse(info);

      const profile = { 
        email: decoded.email,
        expiration: decoded.exp,
        picture: objInfo.picture
      };

      return { isActive: true, data: profile };
    }
    else {
      return { isActive: false, data: null };
    }
  } catch(error){
    return { isActive: false, data: null };
  }
}

const logIn = () => {
  try{
    let token = localStorage.getItem("token");
    let info = localStorage.getItem("profile");
  
    const decoded = jwt_decode(token);
    const objInfo = JSON.parse(info);
  
    const profile = { 
      email: decoded.email,
      expiration: decoded.exp,
      picture: objInfo.picture
    };
  
    return { isActive: true, data: profile};
  } catch(error) {
    return { isActive: false, data: null};
  }
}

const logOut = () => {
  try{
    localStorage.setItem('token', null);
    return { isActive: false, data: null};
  } catch(error){
    return { isActive: false, data: null}; 
  }
}

export const { sessionIsActive, getSessionInformation, logInSession, logOutSession } = sessionSlice.actions;
export default sessionSlice.reducer;