import React, { useEffect, useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
//import { useNavigate } from "react-router-dom";
import {jwtDecode as jwt_decode} from 'jwt-decode';
import axios from 'axios';

import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Home from './components/Home';
import About from './components/About';
import Profile from './components/Profile';
import Products from './components/Products';
import NotFound from './components/NotFound';
import './styles/AuthBox.css';

import { useSelector, useDispatch } from 'react-redux';
import { getSessionInformation, logInSession, logOutSession } from './store/sessionSlice';

const App = () => {
  const session = useSelector((state) => state.session );
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    dispatch(getSessionInformation());
  }, [dispatch]);

  useEffect(() => {
    if(session){
      setProfile(session);
    }
  }, [session]);

  const onLogOutSession = (e) => {
    dispatch(logOutSession());
  }

  const onLoginSession = (e) => {
    setShowLogin(true);
  }

  /*********************/
  const apiBase = 'http://localhost:5153/';
    //const navigate = useNavigate();
    const [isRegister, setIsRegister] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiBase}api/auth/register`, formData);
            alert(response.data.message);
        } catch (error) {
            console.error(error);
            alert('Error al registrar el usuario.');
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiBase}api/auth/login`, {
                email: formData.email,
                password: formData.password
            });
            // Guardar el token en localStorage o en una cookie
            localStorage.setItem('token', response.data.token);
            alert('Login exitoso!');
        } catch (error) {
            console.error(error);
            alert('Credenciales inválidas.');
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const { credential } = credentialResponse;
            const decoded = jwt_decode(credential);
            const googleProfileData = {
                email: decoded.email,
                email_verified: decoded.email_verified,
                name: decoded.name,
                picture: decoded.picture
            };

            // Enviar el token al backend para autenticación
            const res = await axios.post(`${apiBase}api/auth/google-login`, {
                token: credential
            });

            // Guardar el token JWT recibido
            localStorage.setItem('token', JSON.stringify(res.data.token));
            localStorage.setItem('profile', JSON.stringify(googleProfileData));

            //navigate("/profile");
            dispatch(logInSession());
        } catch (error) {
            console.error(error);
            alert('Error al autenticar con Google.');
        }
    };

    const handleGoogleFailure = (error) => {
        console.error(error);
        alert('Error al autenticar con Google.');
    };

    const handleFacebookResponse = async (response) => {
        try {
            facebookLogin(response.userID, response.accessToken);
            let token = localStorage.getItem("token");
            getUSerProfileInfo(token);
            
        } catch (error) {
            console.error(error);
            alert('Error al autenticar con Facebook.');
        }
    };

    //make the login handshake.
    const facebookLogin = async (userId, accessToken) => {
        const res = await axios.post(`${apiBase}api/auth/facebook-login`, {
            AccessToken: accessToken,
            UserID: userId
        });

        localStorage.setItem("token", JSON.stringify(res.data.token));
    }

    //It gets the user info after the user is authenticated.
    const getUSerProfileInfo = (token) => {
        axios.get(`${apiBase}api/auth/GetUserProfileInfo`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            logUserIn(response);
        });
    }

    const logUserIn = (userInfoData) => {
        
    }

    const closeLoginDialog = () => {
      setShowLogin(false);
    }

  return (
    <div className="row h-justify-space-between header-bar ">
      <div className="column v-center">
        <div aria-label="tecolote logo image" className='menu-header-logo column-inline'></div>
      </div>
      <div className="column v-center">
        <nav className='column-inline'> 
          <ul className='menu-link-group row'>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/about">Acerca</Link></li>
            <li><Link to="/profile">Perfil</Link></li>
            <li><Link to="/products">Productos</Link></li>
          </ul>
        </nav>
      </div>
      <div className="column v-center">
        { 
          profile && profile.data && profile.data !== "null" ? 
          <div className='column default-padding h-center'>
            <div className='row h-center profile-text text-align-center'>
              <img src={profile.data.picture} alt="profile" className='column' style={{width: "32px", height: "32px", marginRight: "6px"}}></img>
              <div className='column v-center'>{`Welcome ${profile.data.email}`}</div>
            </div>
            <div className='row v-center text-align-center'>
              <div className='logout-btn' title="log out" onClick={onLogOutSession}></div>
            </div>
          </div> : 
          <div className='column default-padding h-center'>
            <div className='row h-center profile-text text-align-center'>Login</div>
            <div className='row v-center text-align-center'>
              <div className='login-btn' title="log in" onClick={onLoginSession}></div>
            </div>
          </div>
        }
      </div>
      
      { showLogin && session && session.isActive !== true &&
      <div className="dialog-modal">
        <div className='close-icon close-dialog-button' onClick={closeLoginDialog}></div>
          <div className="center-content">
              <div className="auth-box">
                  {!isRegister ? (
                      <>
                          <h2>Iniciar Sesión</h2>
                          <form onSubmit={handleLogin}>
                              <input
                                  type="email"
                                  name="email"
                                  placeholder="Correo Electrónico"
                                  value={formData.email}
                                  onChange={handleChange}
                                  required
                              />
                              <input
                                  type="password"
                                  name="password"
                                  placeholder="Contraseña"
                                  value={formData.password}
                                  onChange={handleChange}
                                  required
                              />
                              <button type="submit">Iniciar Sesión</button>
                          </form>
                          <div className="social-login">
                              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                  <GoogleLogin
                                      onSuccess={handleGoogleSuccess}
                                      onFailure={handleGoogleFailure}
                                  />
                              </GoogleOAuthProvider>
                              <FacebookLogin
                                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                  callback={handleFacebookResponse}
                                  render={renderProps => (
                                      <button onClick={renderProps.onClick} className="facebook-button">
                                          Iniciar con Facebook
                                      </button>
                                  )}
                              />
                          </div>
                          <p>
                              ¿No tienes una cuenta?{' '}
                              <span onClick={() => setIsRegister(true)}>Crear Cuenta</span>
                          </p>
                      </>
                  ) : (
                      <>
                          <h2>Crear Cuenta</h2>
                          <form onSubmit={handleRegister}>
                              <input
                                  type="text"
                                  name="firstName"
                                  placeholder="Nombre"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  required
                              />
                              <input
                                  type="text"
                                  name="lastName"
                                  placeholder="Apellido"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  required
                              />
                              <input
                                  type="email"
                                  name="email"
                                  placeholder="Correo Electrónico"
                                  value={formData.email}
                                  onChange={handleChange}
                                  required
                              />
                              <input
                                  type="password"
                                  name="password"
                                  placeholder="Contraseña"
                                  value={formData.password}
                                  onChange={handleChange}
                                  required
                              />
                              <input
                                  type="password"
                                  name="confirmPassword"
                                  placeholder="Confirmar Contraseña"
                                  value={formData.confirmPassword}
                                  onChange={handleChange}
                                  required
                              />
                              <button type="submit">Crear Cuenta</button>
                          </form>
                          <p>
                              ¿Ya tienes una cuenta?{' '}
                              <span onClick={() => setIsRegister(false)}>Iniciar Sesión</span>
                          </p>
                      </>
                  )}
              </div>
          </div>
      </div>
      }

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/products" element={<Products />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;